/* eslint-disable react/prop-types */
import { useState } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import P, { shape } from 'prop-types';
import ConfirmationDialog from '../ConfirmationDialog';
import UserForm from '../UserForm';

const UserTableList = ({ listItem, deleteUser, title, mutate }) => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [user, setUser] = useState(null);
  const [dialog, setDialog] = useState({
    msg: '',
    title: '',
  });

  const handleEdit = async (item) => {
    setUser(item);
    setOpen(true);
  };

  return (
    <>
      <ConfirmationDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        msg={dialog.msg.length > 0 ? dialog.msg : 'Dialog'}
        title={dialog.title.length > 0 ? dialog.title : 'Dialog'}
      />
      <UserForm open={open} setOpen={setOpen} title={title} user={user} mutate={mutate} />
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="50%">Name</TableCell>
              <TableCell>Login</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listItem !== null &&
              listItem.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell>
                    {item.name}
                  </TableCell>
                  <TableCell>
                    {item.login}
                  </TableCell>
                  <TableCell>
                    {item.status == "1" ? 'Ativo' : 'Inativo'}
                  </TableCell>
                  <TableCell align="right">
                  {item.verify?.verified === 0 && (
                      <IconButton onClick={() => handleEdit(item)}>
                        <FiberNewIcon color="action" />
                      </IconButton>
                    )}
                    <IconButton onClick={() => handleEdit(item)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

UserTableList.propTypes = {
  listItem: P.arrayOf(
    shape({
      id: P.number,
      name: P.string,
    })
  ),
  title: P.string.isRequired,
};

UserTableList.defaultProps = {
  listItem: null,
};

export default UserTableList;
