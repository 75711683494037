import styled from 'styled-components';


export const Box = styled.div`
    margin: 5px 5px 5px 5px;
    padding: 5px 5px 5px 5px;

    h4 {
        color: #444;
    }

    p {
        font-size: 12px      
    }
`;