/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import P from 'prop-types';
import useSWR from 'swr';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';
import { MaskedTextField } from '../FormFields';
import useApp from '../../hooks/useApp';
import { editUser } from '../../service/user.service'
import { sendMailVerify } from '../../service/mail.service';
import { PasswordModal } from '../PasswordModal';


const roleTypes = [
  { id: 1, display_name: 'Admin' },
  { id: 2, display_name: 'Gerente Corretora' },
  { id: 3, display_name: 'Corretor' },
  { id: 4, display_name: 'Gerente Concessionária' },
  { id: 5, display_name: 'Indicador' },
  { id: 7, display_name: 'Indicador F&I' },
];

export default function UserForm({ open, setOpen, title, user, mutate }) {
  const { data: commissionSchema } = useSWR('/commissions-schema', fetcher);
  const {channels, partners, technical_teams} = useApp();
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    observation: '',
    role: null,
    channel: null,
    partners: null,
    technical_team: null,
    status: false,
    renovator_hierarchical_group: null,
    openPasswordModal: false,
    editedUser: {},
    commissionSchema: null
  });

  const entering = () => {
    setValues({
      name: user.name || '',
      email: user.email || '',
      phone: user.phone || '',
      observation: user.observation || '',
      role: user.roles[0] || null,
      channel: user.channel || null,
      partners: user.partners || null,
      technical_team: user.technical_team || null,
      status: !!user.status || false,
      renovator_hierarchical_group: user.renovator_hierarchical_group || null,
      commissionSchema: user.commissions_schema || null
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = async (e) => {
    e?.preventDefault();

    if (values.partners.length == 0) {
      toast.warning('Informe uma Empresa!');
      return;
    }

    const editedUser = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      role: values.role.id,
      channel_id: values.role.id > 3 ? values.channel.id : 1,
      partners: values.partners,
      technical_team_id: values.technical_team?.id || null,
      status: values.status,
      renovator_hierarchical_group: values.renovator_hierarchical_group,
      commissionSchema_id: values.commissionSchema?.id || null
    };
    try {
      await editUser(editedUser, user.id);

      toast.success('Usuário atualizado com sucesso');
      mutate();
    } catch (error) {
      toast.error('Erro ao modificar usuário');
    }

    handleClose();
  };

  const handleClosePasswordModal = async () => {
    setValues({ ...values, openPasswordModal: false });
  };

  const handleOpenPasswordModal = async () => {
    setValues({ 
      ...values, 
      openPasswordModal: true ,
      editedUser : {
        id: user.id,
        email: values.email,
      }
    });
  };

  const handleChangeAutoComplete = (event, newValue, variable) => {
    if (newValue) {
      setValues({
        ...values,
        [variable]: newValue,
      });
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const phoneMask = (phone) => {
    if (values.role && values.role.id >= 4) {
      if (phone.replace(/[^\d]/g, '').length < 11) return '(99)9999-99999';
      return '(99)99999-9999';
    }
    return '99999999999';
  };

  return (
    <div>
      <Dialog
        TransitionProps={{onEntering: entering}}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md">
        <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {title}
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle >
        <form onSubmit={submit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Nome"
              type="name"
              value={values.name}
              fullWidth
              required
              onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
            <TextField
              autoFocus
              margin="dense"
              label="E-mail"
              type="email"
              value={values.email}
              fullWidth
              required
              onChange={(e) => setValues({ ...values, email: e.target.value })}
            />
            <MaskedTextField
              mask={phoneMask(values.phone)}
              variant="standard"
              value={values.phone || ''}
              onChange={handleChange}
              varibleName="phone"
              label="TELEFONE"
              fullWidth
              required
            />
            <Autocomplete
              options={roleTypes}
              value={values.role}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option?.display_name}
              onChange={(event, newValue) => {
                handleChangeAutoComplete(event, newValue, 'role');
              }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Função" required />}
            />
            {values.role?.id > 3 && (
              <Autocomplete
                options={channels}
                value={values.channel}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option?.name}
                onChange={(event, newValue) => {
                  handleChangeAutoComplete(event, newValue, 'channel');
                }}
                renderInput={(params) => <TextField {...params} variant="standard" label="Canal" required />}
              />
            )}
            {values.role?.id <= 3 && (
              <>
                <Autocomplete
                  options={technical_teams}
                  value={values.technical_team}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option?.description}
                  onChange={(event, newValue) => {
                    handleChangeAutoComplete(event, newValue, 'technical_team');
                  }}
                  renderInput={(params) => <TextField {...params} variant="standard" label="Equipe técnica" required/>}
                />
                <MaskedTextField
                  mask="999999999999999"
                  variant="standard"
                  value={values.renovator_hierarchical_group || ''}
                  onChange={handleChange}
                  varibleName="renovator_hierarchical_group"
                  label="Grupo Hierárquico Renovador"
                  fullWidth
                />
              </>
            )}

            <Autocomplete
              options={commissionSchema}
              value={values.commissionSchema || []}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option?.name}
              onChange={(event, newValue) => {
                handleChangeAutoComplete(event, newValue, 'commissionSchema');
              }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Tabela de Comissão" />}
            />

            <div style={{ margin: '20px 0' }}>
              <h4>Observação</h4>
              <p>{values.observation}</p>
            </div>
            <Autocomplete
              required
              multiple
              options={partners}
              value={values.partners || []}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option?.name}
              onChange={(event, newValue) => {
                handleChangeAutoComplete(event, newValue, 'partners');
              }}
              renderInput={(params) => <TextField {...params} variant="standard" label="Empresas" />}
            />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.status}
                    onChange={(e) => setValues({ ...values, status: e.target.checked })}
                    color="default"
                  />
                }
                label="Usuário ativo"
              />
          
          </DialogContent>
          <DialogActions style={{position: 'sticky', bottom: 0, backgroundColor:'white', width:'100%',}}>
            {values.status ? (
              <Button color="primary" onClick={handleOpenPasswordModal}>
                Alterar a senha
              </Button>
            ) : (
              ''
            )}
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </DialogActions>

        </form>
      </Dialog>

      <PasswordModal
            open={values.openPasswordModal}
            setOpen={handleClosePasswordModal}
            editedUser={values.editedUser}
          />

    </div>
  );
}

UserForm.propTypes = {
  open: P.bool.isRequired,
  title: P.string.isRequired,
  setOpen: P.func.isRequired,
  user: P.objectOf(P.string),
};

UserForm.defaultProps = {
  user: null,
};
