import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';

export const Logo = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background-color: rgb(250 156 18);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  /* text-transform: uppercase; */
  cursor: pointer;
  margin-right: 16px;
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    .sub-text {
      font-size: 12px;
      font-weight: 300;
      color: grey;
    }

    :focus {
      background: transparent;

      .MuiListItemIcon-root,
      .MuiListItemText-primary {
        color: inherit;
      }
    }
  }
`;
