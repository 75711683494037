/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable */
import P from 'prop-types';
import useSWR from 'swr';
import { useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button } from '@material-ui/core';
import { Container, FormGroup, LoadingDiv } from './styles';
import { TextField, AutocompleteField, DateField, MaskedTextField, TextFieldAdorned } from '../FormFields';
import { RemoveMask } from '../../utils/phoneMask';
import Loading from '../Loading';
import { checkCEP, checkCPF, checkAge61, handleSaveFlowService } from '../../service/lead.service';
import { months, UF } from '../../mock/leadInfo.com';
import { useAuth } from '../../hooks/useAuth';
import { dataCreate } from '../../service/data.service';
import useApp from '../../hooks/useApp';
import { fetcher } from '../../service/SWRFetcher';
import { editClient, getClientByCpf } from '../../service/client.service';
import { AutoIndicacao } from '../../context/Channels';
import { IsCorujaFlow } from '../../service/flow.service.ts';

export default function SelfIndicationForm({ type }) {
  const { user } = useAuth();

  const { insurances, partners, channels } = useApp();

  const { data: usersData } = useSWR(`/users`, fetcher);

  const form = useRef(null);
  const [createError, setCreateError] = useState(false);
  const [finish, setFinish] = useState(false);
  const [close, setClose] = useState(false);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    // -->inviter information<--
    inviter: null,
    inviter_Phone: '',
    partner: null,
    insurance: null,
    // -->client information<--
    proposal: '',
    client_name: '',
    client_cellphone: '', 
    client_phone: '',
    client_birth_date: '',
    client_Cpf: '',
    client_Rg: '',
    client_CEP: '',
    client_address_street: '',
    client_address_number: '',
    client_address_comp: '',
    client_address_district: '',
    client_address_city: '',
    client_address_state: '',
    client_Email: '',
    channel: '',
    insurance_expiration: null,
    // -->lead table<--
    product: '',
    product_details: '',
    additional_information: '',
    indication_on_time: null,

    broker: null, // users ->removido
    status: null,
    state: null,
    date_fail: false,
  });

  const Loadingg = (value) => {
    setLoading(value);
  };

  const clear = async () => {
    setValues({
      ...values,
      inviter: user.data,
      inviter_Phone: user.phone,
      channel: channels.find((channel) => channel.id == AutoIndicacao ) || null,
      partner: partners?.find((partner) => partner.id == 24 ) || null,
      insurance: null,
      proposal: '',
      client_name: '',
      client_cellphone: '',
      client_phone: '',
      client_birth_date: '',
      client_Cpf: '',
      client_Rg: '',
      client_CEP: '',
      client_address_street: '',
      client_address_number: '',
      client_address_comp: '',
      client_address_district: '',
      client_address_city: '',
      client_address_state: '',
      client_Email: '',
      insurance_expiration: null,
      product: '',
      product_details: '',
      product_license_plate: '',
      product_year: '',
      product_brand: '',
      additional_information: '',
      date_fail: false,
    });
  };

  function formatDate(date) {
    if (!date) return null;
    const result = date.split("-");
    return result[2] + "-" + result[1] + "-" + result[0]
  }

  function formatDateSave(date) {
    const result = date.split("/");
    if(result[2]){
      return result[2] + "-" + result[1] + "-" + result[0]
    } else {
      return date
    }
  }

  useEffect(() => {
    (async () => {
      Loadingg(true);
      clear();
      Loadingg(false);
    })();
  }, [user]);

  const checkIsLifeInsurance = () => values.insurance?.id === 9;
  
  const handleSave = async () => {
    if (!handleSaveFlowService(checkIsLifeInsurance(), values.client_birth_date)) {
      return;
    }

    try {
      const client = await getClientByCpf(values.client_Cpf);
      const clientData = {
        name: values.client_name,
        phone: (values.client_phone && RemoveMask(values.client_phone)) || ' ',
        cellphone: (values.client_cellphone && RemoveMask(values.client_cellphone)) || ' ',
        mail: values.client_Email,
        cpf: (values.client_Cpf && RemoveMask(values.client_Cpf)) || '',
        rg: (values.client_Rg && RemoveMask(values.client_Rg)) || null,
        birth_date: formatDateSave(values.client_birth_date) || '',
        address: {
          cep: (values.client_CEP && RemoveMask(values.client_CEP)) || null,
          street: values.client_address_street || null,
          number: values.client_address_number || null,
          comp: values.client_address_comp || null,
          district: values.client_address_district || null,
          city: values.client_address_city || null,
          state: values.client_address_state || null,
        },
      };
      if (client) {
        await editClient(clientData, client.id);
      } 
      
      const leadData = {
        proposal: values.proposal || null,
        product: values.product,
        product_details: values.product_details,
        channel_id: AutoIndicacao,
        inviter_id: values.inviter?.id,
        insurance_id: values.insurance.id,
        insurance_expiration: values.insurance_expiration ? values.insurance_expiration?.id : 0,
        partner_id: values.partner.id,
        additional_information: values.additional_information || ' ',
        state: values.state || null,
        date_fail: values.date_fail,
        client_name: values.client_name,
        client_phone: (values.client_phone && RemoveMask(values.client_phone)) || ' ',
        client_cellphone: (values.client_cellphone && RemoveMask(values.client_cellphone)) || ' ',
        client_email: values.client_Email,
        client_cpf: (values.client_Cpf && RemoveMask(values.client_Cpf)) || '',
        client_rg: values.client_Rg || null,
        client_birth_date: formatDateSave(values.client_birth_date) || '',
        client_address: {
          cep: (values.client_CEP && RemoveMask(values.client_CEP)) || null,
          street: values.client_address_street || null,
          number: values.client_address_number || null,
          comp: values.client_address_comp || null,
          district: values.client_address_district || null,
          city: values.client_address_city || null,
          state: values.client_address_state || null,
        },
        client_nacionalidade: 'Brasileiro',
      };
      const editResponse = await dataCreate('/external-lead', leadData);
      if (editResponse) {
        toast.success('Lead criado com sucesso!');
        if (editResponse.indication_on_time == 0) toast.warning('Lead indicada fora do prazo!');
      }
      clear();
    } catch (error) 
    {
      console.log('error:', error.response);
      
      if (error.response?.status === 401) {
        const msg = JSON.stringify(error.response.data.data);
        toast.warning(`Verificar se todos os campos requeridos foram preenchidos! ${msg}`, { autoClose : false });
        
        return;
      }
      if (error.response?.status === 403) {
        toast.warning('Sem permissão para criar esse tipo de Lead (Partner not found)');
        return;
      }
      let msg;
      if (error?.statusError === 1) {
        msg = error.message;
        setCreateError(true);
      } 
      else 
        msg = `Este usuário esta sem permissão! Verifique os parceiros vinculados ao usuário (WEB, CALL NOW e PARCEIROS).`;

      toast.warning(msg);
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeAutoComplete = (event, newValue, variable) => {
    setValues({
      ...values,
      [variable]: newValue,
    });
  };

  useEffect(() => {
    if (finish != false) {
      handleSave();
      setFinish(false);
    }
  }, [finish]);

  useEffect(() => {
    if (close != false) {
      setClose(false);
    }
  }, [close]);

  // Buttons
  const ButtonDiv = () => (
    <div className="form-buttons" style={{ margin: '14px 0' }}>
      <Button type="submit" variant="contained" color="default">
        Salvar
      </Button>

      {type !== 'edit' && (
        <Button onClick={clear} variant="contained" color="default">
          Descartar
        </Button>
      )}
    </div>
  );

  return (
    <Container>
      {loading && (
        <LoadingDiv>
          <Loading />
        </LoadingDiv>
      )}
      <form
        ref={form}
        onSubmit={(e) => {
          e.preventDefault();
          setFinish(true);
        }}>
        {values?.insurance?.id != '25' && (
          <div>
            <h2>Informações do Indicador</h2>
            <div className="form">
              <FormGroup dark>
                <AutocompleteField
                  medium
                  className="control g"
                  valueText={values.inviter ? values.inviter.name : ''}
                  options={usersData ? usersData?.filter((u) => u.roles[0]?.id !== 6) : [values.inviter]}
                  value={values.inviter || null}
                  varibleName="inviter"
                  label="INDICADOR"
                  disabled
                />
                <MaskedTextField
                  mask={
                    values.inviter_Phone && values.inviter_Phone.replace(/[^\d]/g, '').length < 11
                      ? '(99)9999-99999'
                      : '(99)99999-9999'
                  }
                  value={values.inviter_Phone || ''}
                  onChange={handleChange}
                  varibleName="inviter_Phone"
                  label="TELEFONE"
                  disabled
                />
              </FormGroup>

              <FormGroup>
                <Box
                  paddingX="5px"
                  color="#00000087"
                  display="flex"
                  my="auto"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  bgcolor="background.paper"></Box>
                <TextFieldAdorned
                  className="control g"
                  value={values.partner?.name || ''}
                  varibleName="partner"
                  label="EMPRESA NA QUAL TRABALHO"
                  disabled
                />
                <AutocompleteField
                medium
                className="control g"
                //options={insurances.filter((i) => i.id <= 20)}
                options={insurances}
                value={values.insurance || null}
                varibleName="insurance"
                onChange={handleChangeAutoComplete} 
                label="TIPO DE SEGURO"
                required
                />
              </FormGroup>
            </div>
          </div>
        )}
        <div>
          <h2>Informações do Indicado</h2>
          <div className="form">
            <FormGroup dark>
              <MaskedTextField
                mask={
                  values?.client_Cpf && values?.client_Cpf?.replace(/[^\d]/g, '').length < 12
                    ? '999.999.999-999'
                    : '99.999.999/9999-99'
                }
                value={values.client_Cpf || ''}
                onChange={handleChange}
                onBlur={async (e) => await checkCPF(e, values, setValues)}
                varibleName="client_Cpf"
                label="CPF/CNPJ"
                className="control s"
                required
              />
              <MaskedTextField
                value={values.client_Rg || ''}
                onChange={handleChange}
                varibleName="client_Rg"
                label="RG"
                className="control s"
                mask="999999999"
              />
              <TextField
                value={values.client_name || ''}
                onChange={handleChange}
                varibleName="client_name"
                label="NOME DO CLIENTE"
                className="control gg"
                required
              />
            </FormGroup>

            <FormGroup dark>
              <MaskedTextField
                value={values.client_cellphone || ''}
                onChange={handleChange}
                varibleName="client_cellphone"
                label="TELEFONE CELULAR"
                required={
                  (!values.client_phone && !values.client_cellphone) ||
                  (!values.client_phone && !!values.client_cellphone)
                }
                mask={
                  values.client_cellphone && values.client_cellphone.replace(/[^\d]/g, '').length < 11
                    ? '(99)9999-99999'
                    : '(99)99999-9999'
                }
              />
              <MaskedTextField
                value={values.client_phone || ''}
                onChange={handleChange}
                varibleName="client_phone"
                label="TELEFONE FIXO"
                required={
                  (!values.client_phone && !values.client_cellphone) ||
                  (!!values.client_phone && !values.client_cellphone)
                }
                mask={
                  values.client_phone && values.client_phone.replace(/[^\d]/g, '').length < 11
                    ? '(99)9999-99999'
                    : '(99)99999-9999'
                }
              />
              <MaskedTextField
                value={formatDate(values.client_birth_date) || ''}
                onChange={handleChange}
                varibleName="client_birth_date"
                label="DATA DE NASCIMENTO"
                onBlur={() => { checkAge61(values.client_birth_date, checkIsLifeInsurance()); }}
                required={IsCorujaFlow()}
                mask={"99/99/9999"}
              />
            </FormGroup>

            <FormGroup dark>
              <MaskedTextField
                value={values.client_CEP || ''}
                onChange={handleChange}
                onBlur={() => checkCEP(values, setValues)}
                varibleName="client_CEP"
                label="CEP"
                className="control s"
                mask="99999-999"
                required
              />
              <TextField
                value={values.client_address_street || ''}
                onChange={handleChange}
                varibleName="client_address_street"
                label="Endereço"
                className="control xl"
                required
              />
              <TextField
                value={values.client_address_number || ''}
                onChange={handleChange}
                varibleName="client_address_number"
                label="Número"
                type="number"
                className="control s"
                required
              />
              <TextField
                value={values.client_address_comp || ''}
                onChange={handleChange}
                varibleName="client_address_comp"
                label="Complemento"
                className="control s"
              />
            </FormGroup>
            <FormGroup dark>
              <TextField
                value={values.client_address_district || ''}
                onChange={handleChange}
                varibleName="client_address_district"
                label="Bairro"
              />
              <TextField
                value={values.client_address_city || ''}
                onChange={handleChange}
                varibleName="client_address_city"
                label="Cidade"
                required
              />

              {IsCorujaFlow()?
                <AutocompleteField
                          medium
                          valueText={values.client_address_state ? values.client_address_state : ''}
                          options={UF}
                          value={values.client_address_state || null}
                          varibleName="client_address_state"
                          onChange={handleChangeAutoComplete}
                          label='Estado'
                          required
                        />:
                <TextField
                  value={values.client_address_state || ''}
                  onChange={handleChange}
                  varibleName="client_address_state"
                  label="Estado"
                  className="control ms"
                  required
                />}

            </FormGroup>

            <FormGroup dark>
              <TextField
                type="email"
                value={values.client_Email || ''}
                onChange={handleChange}
                varibleName="client_Email"
                label="E-MAIL"
                className="control gg"
                required
              />

              <AutocompleteField
                medium
                className="control m"
                valueText={values.insurance_expiration ? values.insurance_expiration.name : ''}
                options={months}
                value={values.insurance_expiration || null}
                varibleName="insurance_expiration"
                onChange={handleChangeAutoComplete}
                label="JÁ POSSUI SEGURO, QUAL O MÊS DO VENCIMENTO"
              />
            </FormGroup>

            <FormGroup>
              <TextField
                value={values.product || ''}
                onChange={handleChange}
                varibleName="product"
                label="PRODUTO SEGURADO"
              />
            </FormGroup>

            <FormGroup>
              <TextField
                value={values.product_details || ''}
                onChange={handleChange}
                varibleName="product_details"
                label="OUTRAS INFORMAÇÕES DO PRODUTO SEGURADO"
                multiline
                rows={5}
              />
            </FormGroup>

            <FormGroup>
              <TextField
                value={values.additional_information || ''}
                onChange={handleChange}
                varibleName="additional_information"
                label="OBSERVAÇÕES"
                multiline
                rows={5}
              />
            </FormGroup>
          </div>
        </div>
        <ButtonDiv />
        <button ref={form} type="submit" hidden>
          hidden save
        </button>
      </form>
    </Container>
  );
}

SelfIndicationForm.propTypes = {
  setOpen: P.func,
  leadCurrent: P.shape({
    id: P.number,
    proposal: P.number,
    product: P.string,
    product_details: P.oneOfType([P.arrayOf(P.any), P.objectOf(P.any)]),
    channel_id: P.number,
    client_id: P.number,
    broker_id: P.number,
    inviter_id: P.number,
    status: P.number,
    state: P.number,
    insurance_id: P.number,
    insurance_expiration: P.number,
    partner: P.objectOf(P.any),
    broker: P.objectOf(P.any),
    channel: P.objectOf(P.any),
    client: P.objectOf(P.any),
    client_adress: P.objectOf(P.any),
    inviter: P.objectOf(P.any),
    inviter_Phone: P.string,
    partner_id: P.number,
    additional_information: P.string,
    indication_on_time: P.number,
  }),
};
SelfIndicationForm.defaultProps = {
  leadCurrent: null,
  setOpen: () => {},
};
