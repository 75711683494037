/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import Person from '@material-ui/icons/Person';
import Assessment from '@material-ui/icons/Assessment';
import PieChart from '@material-ui/icons/PieChart';
import WorkOff from '@material-ui/icons/WorkOff';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CallIcon from '@material-ui/icons/Call';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import TrendingUp from '@material-ui/icons/TrendingUp';
import CodeIcon from '@material-ui/icons/Code';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import DetailsIcon from '@material-ui/icons/Details';
import { List } from '@material-ui/core';
import ListItemLink from './ListItemLink';
import { MainContext } from '../../../context/ApiContext';
import ContactsIcon from '@material-ui/icons/Contacts';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { GetTenantRoute } from '../../../router/tenantRoutes';
import { MonetizationOn } from '@material-ui/icons';

export const MainListItems = ({ newUsers, menuClick }) => {
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = useState();
  const {
    auth: { getUserInfo },
  } = useContext(MainContext);

  useEffect(() => {
    getUserInfo().then((res) => setUser(res.data));
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const IsAdmin = () => user.roles[0].id === 1;
  const IsGerenteCorretora = () => user.roles[0].id === 2;
  const IsCorretor = () => user.roles[0].id === 3;
  const IsGerenteConcessionaria = () => user.roles[0].id === 4;
  const IsIndicador = () => user.roles[0].id === 5;

  const IsAdminAtGerenteCorretora = () => user.roles[0].id <= 2;
  const IsAdminAtCorretor = () => user.roles[0].id <= 3;
  const IsAdminAtConcessionaria = () => user.roles[0].id <= 4;

  const OnlyTestEnvironment = () => GetTenantRoute() == "m3tas" || GetTenantRoute() == "sampleclient";

  const itemsDisplayName = 
    [
      "Criar Lead", // 0
      "Auto Indicação",
      "Usuários", // 2
      "Clientes",
      "Esteira do Cliente",
      "Equipe Técnica", // 5
      "Lead Corretor Config",
      "Rel. Ind. Corretora",
      "Rel. Ind. Conce.", // 8
      "Rel. de Ligações",
      "Rel. de Atendimento", // 10
      "Rel. de Perdas",
      "Indicação Externa", // 12
      "Funil de vendas",
      "Verificação de Leads",
      "Rel. Vendas Quiver", // 15
      "Rel. Processamentos",
      "Rel. Renovações",
      "Rel. Diag. Renovacões", // 18
      "Rel. Diag. Novos",
      "Rel. de Sinistros", // 20
      "Rel. de Endossos",
      "Rel. de Pagamentos",
      "Rel. de Assinaturas", // 23
      "Rel. Indicador",
      "Importar Leads", // 25
      "Rel. de Comissão",
      "Tabela de Comissões", // 27
    ];

  return (
    <List onClick={menuClick}>
      <ListItemLink icon={<DashboardIcon />} text={user && user.roles[0].id < 4 ? 'Home' : 'Indicação'} to="/" />
      {user && IsAdminAtConcessionaria() && (
        <>
          <ListItemLink icon={<BusinessCenter />} text={itemsDisplayName[0]} to="/lead" />
        </>
      )}

      {(user && IsAdminAtCorretor()) && (
        <ListItemLink icon={<BusinessCenter />} text={itemsDisplayName[1]} to="/auto-indicação" />
      )}

      {user && IsAdmin() && (
        <>
          <ListItemLink count={newUsers} icon={<Person />} text={itemsDisplayName[2]} to="/users" />
          <ListItemLink icon={<MonetizationOn />} text={itemsDisplayName[27]} to="/commissions-schema" />
          <ListItemLink icon={<ContactsIcon />} text={itemsDisplayName[3]} to="/clients" />
          <ListItemLink icon={<PeopleIcon />} text={itemsDisplayName[5]} to="/technical-team" />
          <ListItemLink icon={<SettingsIcon />} text={itemsDisplayName[6]} to="/lead-broker-config" /> 
          <ListItemLink icon={<PieChart />} text={itemsDisplayName[7]} to="/relatorio-indicacoes-corretora" />         
          <ListItemLink icon={<LibraryBooksIcon />} text={itemsDisplayName[8]} to="/relatorio-indicacoes-concessionaria" />
          <ListItemLink icon={<CallIcon />} text={itemsDisplayName[9]} to="/relatorio-ligacoes" />
          <ListItemLink icon={<WorkOff />} text={itemsDisplayName[11]} to="/relatorio-perdas" />
          <ListItemLink icon={<RecentActorsIcon />} text={itemsDisplayName[12]} to="/indicacao-externa" />
          <ListItemLink icon={<CodeIcon />} text={itemsDisplayName[14]} to="/conferencia-informacoes-lead" />
          <ListItemLink icon={<TrendingUp />} text={itemsDisplayName[15]} to="/relatorio-vendas-quiver" />
          <ListItemLink icon={<AssignmentIcon />} text={itemsDisplayName[16]} to="/relatorio-processamentos" />
          <ListItemLink icon={<AssignmentIcon />} text={itemsDisplayName[17]} to="/relatorio-renovacoes" />
          <ListItemLink icon={<Assessment />} text={itemsDisplayName[24]} to="/relatorio-indicador" />
        </>
      )}

      {user && IsAdminAtGerenteCorretora() && (
        <>
          <ListItemLink icon={<DashboardIcon />} text={itemsDisplayName[4]} to="/esteira-cliente" />
          <ListItemLink icon={<WatchLaterIcon />} text={itemsDisplayName[10]} to="/relatorio-atendimento" />
          <ListItemLink icon={<DetailsIcon />} text={itemsDisplayName[13]} to="/funnel-charts" />
          <ListItemLink icon={<AssignmentIcon />} text={itemsDisplayName[18]} to="/relatorio-diagnostico" />
          <ListItemLink icon={<LibraryBooksIcon />} text={itemsDisplayName[19]} to="/relatorio-novos-seguros" />          
          <ListItemLink icon={<LibraryBooksIcon />} text={itemsDisplayName[20]} to="/relatorio-sinistros" />
          <ListItemLink icon={<LibraryBooksIcon />} text={itemsDisplayName[21]} to="/relatorio-endossos" />
          <ListItemLink icon={<LibraryBooksIcon />} text={itemsDisplayName[22]} to="/relatorio-pagamentos" />
          <ListItemLink icon={<LibraryBooksIcon />} text={itemsDisplayName[23]} to="/relatorio-assinaturas" />
          <ListItemLink icon={<LibraryBooksIcon />} text={itemsDisplayName[26]} to="/relatorio-comissao" />
        </>
      )}

      {user && (IsIndicador() || user.roles[0].id == 7) && (
        <>
          <ListItemLink icon={<Assessment />} text={itemsDisplayName[24]} to="/relatorio-indicador" />
          <ListItemLink icon={<RecentActorsIcon />} text={itemsDisplayName[12]} to="/indicacao-externa" />
        </>
      )}

      {user && IsCorretor() && (
        <>
          <ListItemLink icon={<AssignmentIcon />} text={itemsDisplayName[17]} to="/relatorio-renovacoes" />
        </>
      )}

      {user && (IsGerenteCorretora() || IsCorretor()) && (
        <>
          <ListItemLink icon={<PieChart />} text={itemsDisplayName[7]} to="/relatorio-indicacoes-corretora" />
          <ListItemLink icon={<WorkOff />} text={itemsDisplayName[11]} to="/relatorio-perdas" />
          <ListItemLink icon={<CallIcon />} text={itemsDisplayName[9]} to="/relatorio-ligacoes" />
          <ListItemLink icon={<ContactsIcon />} text={itemsDisplayName[3]} to="/clients" />
          <ListItemLink icon={<CodeIcon />} text={itemsDisplayName[14]} to="/conferencia-informacoes-lead" />
        </>
      )}

      {user && IsGerenteConcessionaria() && (
        <ListItemLink icon={<LibraryBooksIcon />} text={itemsDisplayName[8]} to="/relatorio-indicacoes-concessionaria" />
      )}

      {user && IsAdminAtGerenteCorretora() && (
        <>
          <ListItemLink icon={<ImportExportIcon  />} text={itemsDisplayName[25]} to="/importar-leads" />
        </>
      )}

    </List>
  );
};
