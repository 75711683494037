/* eslint-disable no-param-reassign */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, TextField, FormControlLabel } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import useSWR from 'swr';
import { fetcher, variantFetcher } from '../../service/SWRFetcher';
import { Header, Container } from '../../styles/shared/styles';
import { GetTenantRoute } from '../../router/tenantRoutes';
import { sendFile } from '../../service/lead.service';
import { Box } from './styles';
import { AutocompleteField } from '../../components/FormFields';
import SimpleModelSheet from '../../assets/files/Modelo Simples em Planilha.xlsx';

const ImportarLeads = () => {
    const { data: users } = useSWR('/users', fetcher);
    const [importFile, setImportFile] = useState(false);
    const [importFileError, setImportFileError] = useState(null);
    const history = useHistory();
    const [values, setValues] = useState({
        selectValues: false,
        selectedUser: null,
        selectedCity: null,
        selectedState: null,
        productName: null,
    });

    const GetDocUrl = () => 
        `${process.env.REACT_APP_API_BASE_URL.replace("{tenantId}",GetTenantRoute())}${"/doc"}`;

    const fileUpload = async (event) => {
        if (event.target.files[0] == null) {
            return false;
        }
        if (event.target.files[0].type != 'text/csv' && 
            event.target.files[0].type != 'text/plain') 
        {
            toast.warning('Formato de arquivo inválido! Somente arquivos Texto');
            return false;
        }
        
        setImportFile(event.target.files[0]);
        const data = {
            file: event.target.files[0],
        };

        let queryString = '';
        if (values.selectValues) {
            queryString = 
                `selectedUser=${values.selectedUser?.id ?? ''} 
                &selectedCity=${values.selectedCity ?? ''}
                &selectedState=${values.selectedState?.toUpperCase() ?? ''}
                &selectedProductName=${values.productName ?? ''}
                `;
        }

        const result = await sendFile(`/lead/importfilelead?${queryString}`, 
            data, setImportFile, 
            'importLead');

        if (result != true) {
            alert(`${result.message} ${result.data}`);
            
            history.push('/');
            return false;
        }

        history.push('/');
        return result;
    };          

    const handleCheckboxChange = (e) => {
        setValues({...values, selectValues: e.target.checked});
    }

    const handleTextChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const handleAutoCompleteChange = (e, newValue, variable) => {
        setValues({
            ...values,
            [variable]: newValue,
          });
    }

    return (
        <motion.div initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }}>
            <Container>
                <Header>
                    <h2>Importar Leads</h2>
                </Header>

                <Box>
                    <h5>{importFileError}</h5>
                </Box>
                {/* <Box m={5}>
                        Documentação da Api: <a href={GetDocUrl()} target='_blank' rel="noreferrer">Acessar Documentação</a>
                </Box> */}

                <Box style={{border: "1px solid #ccc"}}>

                    <FormControlLabel
                        style={{ marginLeft: 0 }}
                        control={<Checkbox name="selectValues" 
                                    checked={values.selectValues} 
                                    onChange={handleCheckboxChange} />}
                        label={ <span><h5>Selecionar dados</h5></span> }
                    />
                    <p>Os dados selecionados aqui serão fixados para cada Lead importado.</p>
                    

                    <AutocompleteField
                        className="control g"
                        valueText={values.selectedUser}
                        options={users}
                        value={values.selectedUser || null}
                        varibleName="selectedUser"
                        onChange={handleAutoCompleteChange}
                        label="Indicador"
                        disabled={!values.selectValues}
                        style={{width: '200px'}}
                    />

                    <TextField label="Cidade" 
                        name="selectedCity" 
                        checked={values.selectCity}
                        onChange={handleTextChange} 
                        disabled={!values.selectValues}
                        style={{marginLeft: '10px'}}
                    />

                    <TextField label="Estado" 
                        placeholder='Sigla'
                        name="selectedState" 
                        inputProps={{ maxLength: 2, style: { textTransform: "uppercase" } }}
                        checked={values.selectState}
                        onChange={handleTextChange} 
                        disabled={!values.selectValues}
                        style={{marginLeft: '10px', width: '50px'}}
                    />

                    <TextField label="Nome do Produto" 
                        name="productName" 
                        checked={values.productName}
                        onChange={handleTextChange} 
                        disabled={!values.selectValues}
                        style={{marginLeft: '10px'}}
                    />

                </Box>

                <Box m={5}>
                    <Button
                      onChange={async (event) => fileUpload(event)}
                      component="label"
                      variant="contained"
                      color="default"
                      className="of-file"
                      startIcon={<CloudUploadIcon />}>
                      Enviar arquivo para Importação
                      <input type="file" hidden />
                    </Button>
                </Box>

                <Box>
                    <br/>
                    <h4>Exemplo do padrão de importação</h4>
                    <p>O arquivo deve possuir um conteúdo em texto no formato CSV (Dados separados por vírgula(,) ou ponto e vírgula(;))</p>
                    <p><a href={SimpleModelSheet} title='Modelo Simples em Planilha'>Download do modelo</a></p>
                    <br/>
                    <p>As colunas devem seguir a seguinte ordem:</p>
                    <p> clientName (*) <br/>
                        clientPhone (*) <br/>
                        clientMail <br/>
                        clientCpf <br/>
                        clientRg <br/>
                        clientCep <br/>
                        clientRua <br/>
                        clientNumero <br/>
                        clientBairro <br/>
                        clientCidade <br/>
                        clientEstado = (Sigla)(*) <br/>
                        clientCompl <br/>
                        birth_date = (dd/mm/yyyy ou Formato número Excel)(*) <br/>
                        gender = (Masculino, Feminino)(-) <br/>
                        estado_civil = (Solteiro, Casado, Divorciado, Separado, Viúvo)(-) <br/>
                        servidor_publico = (0=Não 1=Sim)(-) <br/>
                        nacionalidade = (Brasileiro)(-) <br/>
                        profissao <br/>
                        leadProductName = (Vida)(-) <br/>
                        leadChannelId = (1=Novos 2=Parceiros 3=WEB 9=Auto Indicação)(-) <br/>
                        leadInviterId (1=Adimin)(-) &#34;Código do usuário&#34;<br/>
                        leadState (1=Aguardando Captura)(-)<br/>
                        leadStatus (1=Aguardando Captura)(-)<br/>
                        leadInsuranceId (9=Vida)(-)<br/>
                        leadPartnerId (24=Empresa Principal)(-)<br/>
                        leadChassis <br/>
                        leadRenavam <br/>
                        leadPlaca <br/>
                        leadMarca <br/>
                        leadEstado <br/>
                        leadMunicipio <br/>
                        leadDataVigencia <br/>
                        leadDataExpiracao <br/>
                        leadAnoModelo <br/>
                        leadQtd <br/>
                        leadRaca <br/>
                        leadDependentes <br/>
                        leadDoencasGraves <br/>
                        leadEstadoCivil <br/>
                        leadContato <br/>
                        </p><br/>
                        <h4>Observações:</h4>
                        <p>
                            Items com (*) são dados obrigatórios que devem ser informados! <br/>
                            Items com (-) são opcionais e podem ficam em branco, será utilizado o valor padrão. <br/>
                            Será validado na inclusão o Nome do Cliente e/ou seu telefone, para evitar duplicar um mesmo cliente.
                        </p>
                </Box>

            </Container>
        </motion.div>
    );
};


export default ImportarLeads;