import styled from 'styled-components';

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 0px;
  margin-bottom: 4px;

  /* max-width: 800px; */
`;

export const NavbarForm = styled.div`
  display: flex;
  background-color: #d7d7d7;
  align-items: center;
  font-size: 12px;

  .date_ini,
  .date_end {
    margin-left: 4px;
  }
  .broker{
    margin-left: 4px;
    input,
    select {
      font: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      width: 100%;
      color: #333;
      border: none;
      height: 30px;
      outline: none;
      display: block;
      padding: 6px 12px;
      border-radius: 8px;
      transition: all 0.2s ease;
      background-color: #e5e5e5;
      border-bottom: 2px solid transparent;

      :hover {
        border-color: #f26522;
      }
    }
  }
  .filter {
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 20px;
    color: #777;
    
  }
  .filter-btn {
    display: flex;
    margin-left: 10px;
    font-size: 10px;
    button {
      height: 30px;
      white-space: nowrap;
      padding: 5px 12px;
      margin: 5px 2px 5px 1px;
      font-size: 10px;
    }
  }

  .name {
    margin-right: 5px;
    width: 250px;
  }
  .lead_id {
    margin-right: 5px;
    width: 100px;
  }

  input {
    font: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    width: 100%;
    color: #333;
    border: none;
    height: 30px;
    outline: none;
    display: block;
    padding: 6px 12px;
    border-radius: 8px;
    transition: all 0.2s ease;
    background-color: #e5e5e5;
    border-bottom: 2px solid transparent;

    :hover {
      border-color: #f26522;
    }
  }

  label {
    color: #444;
    width: 120px;
  }  
`;

export const Links = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .fields {
    display: flex;
  }

  button {
    white-space: nowrap;
    list-style: none;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    display: block;
    padding: 10px 15px;
    line-height: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #777;
    + button {
      margin-left: 10px;
    }
  }
`;
