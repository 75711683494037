/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import P from 'prop-types';
import Button from '@material-ui/core/Button';
import {
    Box, IconButton, Paper,
    Table, TableBody, DialogContent,
    TableCell,
    TableContainer, TableHead, TableRow, makeStyles,
    Collapse,
    Typography, Dialog, DialogTitle, Divider, DialogActions

} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { DivSeparacao, EstornoButton } from './styles';
import { maskDateTime, maskCurrency, maskDate } from '../../utils/masks';
import api from '../../service/api';
import ConfirmModal from './confirmModal';
import { GetInstallmentCalc } from '../../service/lead.service'



function Row(props) {

    const StyledBoxesHeader = {
        marginTop: 16,
        marginBottom: 8,
        marginRight: 6,
        marginLeft: 12,
    };

    const StyledBoxes = {
        marginTop: 16,
        marginBottom: 8,
        marginRight: 6,
        marginLeft: 6,
    };
    const StyledBoxesWidth = {
        marginTop: 16,
        marginBottom: 8,
        marginRight: 6,
        marginLeft: 6,
        width: 280,
    };

    const { row } = props;
    const { assinatura } = props;
    const [open, setOpen] = useState(false);

    const verificarSeIdEstaNaLista = (id) => {
        const arrayOptions = row.observation.split(',').map(item => Number(item));
        if (arrayOptions.length > 0) {
            const verificacao = arrayOptions.some(option => option === id);
            return verificacao;
        }
        return false;
    }

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
        tableCell: {
            fontSize: '14px', // Defina o tamanho da fonte conforme necessário
            whiteSpace: 'nowrap', // Impede a quebra de linha
        },
    });

    const classes = useStyles();

    const [canBeRefunded, setCanBeRefunded] = useState(false)



    const traduzirStatus = (status) => {
        switch (status) {
            case "paid":
                return "Pago";
            case "failure":
                return "Falha";
            case "refunded":
                return "Estornado";
            default:
                return status;
        }
    }

    const checkIfPaymentCanBeRefunded = () => {
        const today = new Date();
        let lastPayment = null;


        row.pagamentos.forEach((pagamento) => {
            const paymentDate = new Date(pagamento.created_at);
            if (
                pagamento.status === 'paid' &&
                paymentDate.getMonth() === today.getMonth() &&
                paymentDate.getFullYear() === today.getFullYear() &&
                (lastPayment === null || paymentDate > new Date(lastPayment.created_at))
            ) {
                lastPayment = pagamento;
            }
        });


        if (lastPayment) {
            return true;
        }
        return false;

    };

    useEffect(() => {
        if (row) {
            setCanBeRefunded(checkIfPaymentCanBeRefunded())
        }
    }, [row]);


    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [paymentId, setPaymentId] = useState();



    const handleOpenConfirmation = () => {
        setConfirmationOpen(true);
    };


    const handleCloseConfirmation = () => {
        setConfirmationOpen(false);

    }


    const handleConfirmEstorno = async () => {
        try {
            const response = await api.post(`maistodos/estornarpagamento/${paymentId}`)


            if (response.status == 200) {
                toast.success("Estorno realizado com sucesso.")
                handleCloseConfirmation();
            }
        } catch (error) {
            toast.error(`Não foi possível realizar o Estorno! ${error}`)
            console.log(error, paymentId)
        }
    };

    const handleEstornar = (pagamento_id) => {
        setPaymentId(pagamento_id)
        handleOpenConfirmation()

    }

    const GetCurrentInstallment = (fatura) => {
        const dataFaturaStr = fatura.created_at.split('T')[0];
        const parcelaAtual = GetInstallmentCalc(assinatura.inicioVigencia, dataFaturaStr);
    
        return `${parcelaAtual} / ${assinatura.periodoMeses}`;
    }


    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>

                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>

                </TableCell>
                <TableCell className={classes.tableCell} >{row.id}</TableCell>
                <TableCell className={classes.tableCell} >{maskDate(row.created_at)}</TableCell>
                <TableCell className={classes.tableCell} >{maskDate(row.updated_at)}</TableCell>
                <TableCell className={classes.tableCell} >{traduzirStatus(row.status)}</TableCell>
                <TableCell className={classes.tableCell} >{GetCurrentInstallment(row)}</TableCell>
                <TableCell className={classes.tableCell} >{maskCurrency(row.valor)}</TableCell>


            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: 10 }}>
                            <Typography variant="h5" gutterBottom component="div">
                                Detalhes da Fatura:
                            </Typography>
                            <div style={{ marginBottom: 20 }}>
                                <p>Descrição: {row.descricao}</p>
                            </div>

                            <Typography variant="h6" gutterBottom component="div">
                                Pagamentos:
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow hover>
                                            <TableCell ><b>ID Pagamento</b></TableCell>
                                            <TableCell ><b>Data</b></TableCell>
                                            <TableCell ><b>Status</b></TableCell>
                                            <TableCell ><></></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.pagamentos.map((pagamento) => (
                                            <TableRow key={pagamento.id}>
                                                <TableCell>{pagamento.id}</TableCell>
                                                <TableCell>{maskDate(pagamento.created_at)}</TableCell>
                                                <TableCell>{traduzirStatus(pagamento.status)}</TableCell>
                                                <TableCell>
                                                    {canBeRefunded && (
                                                        <>
                                                            <Button onClick={() => { handleEstornar(pagamento.id) }} color='secondary' >
                                                                Estornar Pagamento
                                                            </Button>

                                                        </>
                                                    )}

                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <ConfirmModal open={confirmationOpen} handleCloseConfirmation={handleCloseConfirmation}
                handleConfirm={handleConfirmEstorno} description='Tem certeza que deseja estornar o pagamento?'
                title='Confirmação de estorno:' />


        </>
    );
}

Row.propTypes = {
    row: P.objectOf(P.string).isRequired,
    assinatura: P.objectOf(P.string).isRequired,
};





export default function ModalHistoricoFaturas({ title, open, setOpen, faturas, assinatura }) {

    const handleClose = () => {
        setOpen(false);
    };


    const [openRow, setOpenRow] = useState(false);

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
        tableCell: {
            fontSize: '18px', // Defina o tamanho da fonte conforme necessário
            whiteSpace: 'nowrap', // Impede a quebra de linha
        },
    });

    const classes = useStyles();


    return (
        <>
            <Dialog onClose={handleClose}
                aria-labelledby="form-dialog-title"
                open={open}
                maxWidth="md"
                fullWidth

                style={{ zIndex: 15615 }}>
                <DialogTitle id="form-dialog-title" >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                        <h3>{title}</h3>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>

                </DialogTitle>
                <DivSeparacao>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell className={classes.tableCell}>ID</TableCell>
                                    <TableCell className={classes.tableCell}>Data Criação</TableCell>
                                    <TableCell className={classes.tableCell}>Data Finalização</TableCell>
                                    <TableCell className={classes.tableCell} >Status</TableCell>
                                    <TableCell className={classes.tableCell} >Nº Parcela</TableCell>
                                    <TableCell className={classes.tableCell} >Valor</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {faturas?.sort((a, b) => b.id - a.id).map((row) => (
                                    <Row key={row.id} row={row} assinatura={assinatura} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DivSeparacao>

            </Dialog>
        </>
    )
}

ModalHistoricoFaturas.propTypes = {
    open: P.bool.isRequired,
    title: P.string.isRequired,
    setOpen: P.func.isRequired,
    faturas: P.objectOf(P.string),
    assinatura: P.objectOf(P.string),
};

ModalHistoricoFaturas.defaultProps = {
    faturas: null,
    assinatura: null,
};
