import { useState, useRef } from 'react';
import P from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import CompTable from '../../../components/CompTable';
import api from '../../../service/api';


export default function ValidationPaymentReport({ open, setOpen, data }) {
    const [getIdentificadorMaistodos, setIdentificadorMaistodos] = useState(null);
    const IdentificadorMaistodosRef = useRef(null);


    const colums = [
        { titulo: '[✔]', variavel: 'check' },
        { titulo: 'Lead', variavel: 'identificadorLead' },
        { titulo: 'Nome', variavel: 'name' },
        { titulo: 'Identificador Maistodos', variavel: 'IdentificadorMaistodos' },
        { titulo: 'Status', variavel: 'status' },
        { titulo: 'Cartão', variavel: 'four_digit_cart' },
        { titulo: 'Data da Assinatura', variavel: 'created_at' },
        { titulo: 'Quantidade Esperada', variavel: 'Esperado' },
        { titulo: 'Quantidade Realizada', variavel: 'Realizado' },
    ];

    const entering = () => {
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleRefreshNotify = async () => {
        const body = {
            identificadorMaistodos: getIdentificadorMaistodos
        };

        try {
            const response = await api.post(`maistodos/refreshNotify`, body);
            toast.success("Solicitação enviada a Mais todos! Pode demorar até 15min.");
        }
        catch (e) {
            toast.error(`Erro ao solicitar Atualização! ${e}`);
        }
    }

    const handleLinkMaisTodos = () => 
        window.open(`https://assinaturas.maistodos.com.br/admin/subscriptions/subscription/${getIdentificadorMaistodos}/change/?_changelist_filters=id%3D${getIdentificadorMaistodos}`, 
            '_blank', 
            'noopener,noreferrer');        
    
    const cellOnClick = (e) => {
        console.log(e);
        console.log(e.target.title);

        if (e.target.innerText == "") {
            e.target.bgColor = e.target.bgColor == "#aaa" ? "" : "#aaa";
        }
        else {
            setIdentificadorMaistodos(e.target.innerText);
            IdentificadorMaistodosRef.current.focus();
        }
    }

    return (
    <div>
        <Dialog
            TransitionProps={{onEntering: entering}}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="md">

            <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#fc986a' }}>
                <div
                    style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    }}>
                    Assinaturas que apresentam Pagamentos com divergências
                    <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle >

            <DialogContent>
                <CompTable data={data} colums={colums} cellOnClick={cellOnClick}/>
            </DialogContent>

            <DialogActions style={{position: 'sticky', bottom: 0, backgroundColor:'white', width:'100%',}}>
                
                <TextField
                    inputRef={IdentificadorMaistodosRef}
                    autoFocus
                    margin="dense"
                    label="Identificador Maistodos"
                    type="IdentificadorMaistodos"
                    value={getIdentificadorMaistodos}
                    style={{width: '200px'}}
                    required
                    onChange={(e) => setIdentificadorMaistodos(e.target.value)}
                />
                
                <Button onClick={handleLinkMaisTodos} color="default"  variant='contained'>
                    Abrir MaisTodos
                </Button>
                <Button onClick={handleRefreshNotify} color="default"  variant='contained'>
                    Atualizar Notificação
                </Button>
                
            </DialogActions>
        </Dialog>
    </div>
    )
}

ValidationPaymentReport.propTypes = {
    open: P.bool.isRequired,
    setOpen: P.func.isRequired,
    data: P.objectOf(P.string).isRequired,
  };