/* eslint-disable camelcase */
/* eslint-disable */

import { useState } from 'react';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import useSWR from 'swr';
import InputMask from 'react-input-mask';
import { IconButton, TablePagination } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LaunchIcon from '@material-ui/icons/Launch';
import { Header, Container } from '../../../styles/shared/styles';
import { fetcher } from '../../../service/SWRFetcher';
import { useAuth } from '../../../hooks/useAuth';
import { excelExport } from '../../../service/excel.service';
import { ExportButton } from './styles';
import Filter from '../../../components/Filter';
import CompTable from '../../../components/CompTable';
import { maskCpf, maskDateTime } from '../../../utils/masks';
import ModalAssinatura from '../../../components/SubscriptionForm';
import api from '../../../service/api';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { TextField } from '@material-ui/core';
import CssStyles from '../css.styles';
import ValidationPaymentReport from '../ValidationPaymentReport';

const SubscriptionsReport = () => {
  const { user } = useAuth();
  const dateNow = DateTime.now().toISODate();
  const dateLastMonth = DateTime.now().plus({ months: -1 }).toISODate();

  const [filters, setFilters] = useState({
    cpf: undefined,
    name: undefined,
    lead: undefined,
    startDate: dateLastMonth,
    endDate: dateNow,
    broker: undefined,
    per_page: 10,
    page: 0,
    status: undefined,
  });
  const [assinatura, setAssinatura] = useState();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [getShowData, setShowData] = useState(false);
  const [openValidationPaymentReport, setOpenValidationPaymentReport] = useState(false);
  const [getValidationPaymentReportData, setValidationPaymentReportData] = useState([]);

  const buscarAssinatura = async (identificador) => {
    try {
      setLoading(true);
      const response = await api.get(`maistodos/assinaturarecorrente/${identificador}`);

      if (response.status == 200 || response.status == 201) {
        setAssinatura(response.data);
        setLoading(false);
      } else {
        toast.error(resp.data.message);
        setLoading(false);
      }
    } catch (error) {
      const msg = 'Não foi possível buscar a Assinatura!';
      if (error.response && (error.response.status === 400 || error.response.status === 500)) {
        toast.error(`${msg} ${error.response.data.message}`);
      } else {
        toast.error(`${msg} ${error}`);
      }
    }
  };
  const Modal = (id) => {
    buscarAssinatura(id);
    setOpen(true);
  };

  const CriarBotao = (id) => (
    <IconButton onClick={() => Modal(id)}>
      <LaunchIcon />
    </IconButton>
  );

  const MontarUrl = () => {
    const filtros = Object.keys(filters).map((x) => (filters[x] != undefined ? `${x}=${filters[x]}` : ''));
    let url = '';
    filtros.forEach((x) => {
      if (url != '') {
        url += '&';
      }
      url += x;
    });
    return url;
  };
  //const { data, mutate, isValidating } = useSWR(`/maistodos/assinaturasrecorrentes?${MontarUrl()}`, fetcher);

  const GetSubscriptionList = async (page) => {
    setShowData(false);
    const response = await api.get(`/maistodos/assinaturasrecorrentes?${MontarUrl(page)}`);
    setData(response.data.data);
    setShowData(true);
  }

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  const Limpar = () => {
    setFilters({
      ...filters,
      cpf: '',
      name: '',
      status: '',
      responsavel: '',
      startDate: dateLastMonth,
      endDate: dateNow,
      per_page: 10,
      page: 0,
      lead: null
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setFilters({ ...filters, page: 0, per_page: parseInt(event.target.value, 10) });
    mutate();
  };
  const handleChangePage = (event, newPage) => {
    setFilters({ ...filters, page: newPage });
    GetSubscriptionList();
  };
  const handleAutoCompleteChange = (e, newValue, variable) => {
    setFilters({
        ...filters,
        [variable]: newValue?.name,
      });
  }

  const statusList = [
    { name: 'Aguardando pagamento', id: 1 },
    { name: 'Pago', id: 2 },
    { name: 'Suspenso', id: 4 },
    { name: 'Cancelado', id: 5 },
    { name: 'Finalizado', id: 6 },
  ]

  const colums = [
    { titulo: 'Lead', variavel: 'lead.id' },
    { titulo: 'ID Assinatura', variavel: 'id' },
    { titulo: 'Cliente', variavel: 'lead.client.name' },
    { titulo: 'CPF', variavel: 'lead.client.cpf', tratamento: (cpf) => maskCpf(cpf) },
    { titulo: 'Data Adesão', variavel: 'created_at', tratamento: (created_at) => maskDateTime(created_at) },
    {
      titulo: 'Status',
      variavel: 'status',
      tratamento: (status) => {
        switch (status) {
          case 'created':
            return 'Aguardando pagamento';
          case 'paid':
            return 'Pago';
          case 'suspended':
            return 'Suspenso';
          case 'canceled':
            return 'Cancelado';
          case 'finished':
            return 'Finalizado';
          default:
            return status;
        }
      },
    },
    { titulo: 'Responsável Financeiro', variavel: 'lead.financialData.nome' },
    { titulo: 'Detalhes', variavel: 'id', tratamento: CriarBotao },
  ];

  const GetValidationPaymentData = async () => {
      const response = await api.get(`/maistodos/validationPaymentReport?${MontarUrl()}`);
      setValidationPaymentReportData(response.data);
      setOpenValidationPaymentReport(true);
  }

  const isAdmin = () => {
    return user.data.roles[0].id === 1;
  }

  return (
    <>
      <ModalAssinatura
        open={open}
        setOpen={setOpen}
        isPaymentPage={false}
        assinatura={assinatura}
        loading={loading}
        buscarAssinatura={buscarAssinatura}
        setLoading={setLoading}
      />

      <motion.div initial={CssStyles.motionDivInitial} animate={CssStyles.motionDivAnimate} exit={CssStyles.motionDivExit}>
        <Container>
          <Header>
            <h1>Relatório de Assinaturas</h1>
          </Header>

          <Filter
            clickFilter={() => {
              if (
                filters.name ||
                filters.cpf ||
                filters.broker ||
                filters.startDate ||
                filters.endDate ||
                filters.lead
              ) {
                GetSubscriptionList();
              }
            }}
            clearFilter={() => {
              Limpar();
            }}>
            
            <Grid container spacing={2}>

            <input type="text" placeholder="Lead" name="lead" value={filters.lead} onChange={handleChange} />

            <input type="text" placeholder="Nome" name="name" value={filters.name} onChange={handleChange} />

            <InputMask
              maskChar=""
              mask="999.999.999-99"
              type="text"
              name="cpf"
              placeholder="CPF"
              value={filters.cpf}
              onChange={handleChange}
            />
            <Autocomplete
              options={statusList}
              getOptionLabel={(option) => option?.name}
              onChange={(event, newValue) => {
                handleAutoCompleteChange(event, newValue, 'status');
              }}
              style={{width: '250px'}}
              size='small'
              renderInput={(params) => (
                <TextField {...params} className="autocomplete" variant='standard' size='small' placeholder="Status" />
              )}
            />

            <datalist id="select">
              <option key="1" value="Adesão">
                {' '}
              </option>
              <option key="2" value="Assinatura">
                {' '}
              </option>
              <option key="3" value="Assinatura + Adesão">
                {' '}
              </option>
            </datalist>
            <input
              type="text"
              placeholder="Responsavel Financeiro"
              name="responsavel"
              value={filters.responsavel}
              onChange={handleChange}
            />
            <input
              type="date"
              name="startDate"
              className="input-form"
              placeholder="Data Inicial"
              value={filters.startDate}
              onChange={handleChange}
            />
            <input
              type="date"
              name="endDate"
              className="input-form"
              placeholder="Data Final"
              value={filters.endDate}
              onChange={handleChange}
            />
          </Grid>
          </Filter>

          {getShowData && 
            (
              <>
                <TablePagination
                  component="div"
                  count={data ? data.total : 0}
                  page={filters.page}
                  onPageChange={handleChangePage}
                  rowsPerPage={filters.per_page}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <CompTable data={data?.data} colums={colums} />
              </>
            )
          }

          <div style={{display: 'inline-block', float: 'right', marginTop: '20px'}}>
            {isAdmin() && (
              <p>
              <ExportButton
                type="button"
                onClick={GetValidationPaymentData}>
                Analisar
              </ExportButton>
              </p>
            )}

            <p>
            <ExportButton
              style={{  marginTop: '5px' }}
              type="button"
              onClick={() => {
                excelExport(
                  `/maistodos/assinaturasrecorrentesexport?${MontarUrl()}`,
                  `relatorio_de_Assinaturas${DateTime.now().toFormat('dd_LL_yyyy')}`
                );
              }}>
              Exportar
            </ExportButton>
            </p>

          </div>

          {isAdmin() && (
            <ValidationPaymentReport open={openValidationPaymentReport} 
              setOpen={setOpenValidationPaymentReport} 
              data={getValidationPaymentReportData} />
          )}

        </Container>
      </motion.div>
    </>
  );
};

export default SubscriptionsReport;

