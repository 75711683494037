/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { DateTime, Interval } from 'luxon';
import { toast } from 'react-toastify';
import { CepVerify } from './cep.service';
import { dataAll, dataCreate, dataDelete, dataEdit } from './data.service';
import { Proposal } from './wf.service';
import {getClientByCpf} from './client.service';
import api from './api';
import { IsCorujaFlow } from './flow.service.ts';


export const createLead = async (newData) => {
  const response = await dataCreate('/leads', newData);
  return response;
};

export const editLead = async (newData, dataID) => {
  const response = await dataEdit('/leads', newData, dataID);
  return response;
};
export const goToNextStatus = async (dataID) => {
  const response = await dataAll(`/leads/next-status/${dataID}`)
  return response;
};

export const deleteLead = async (dataID) => {
  const response = await dataDelete('/leads', dataID);
  return response;
};

const internalCpfValid = (strCPF) => {
  const cpf = strCPF.replace(/[^0-9]/g, '');
  let soma;
  let resto;
  soma = 0;

  if (cpf == "00000000000") 
    return false;

  for (let i=1; i<=9; i+=1) 
    soma += parseInt(cpf.substring(i-1, i), 10) * (11 - i);
  
  resto = (soma * 10) % 11;
  if ((resto == 10) || (resto == 11))  
    resto = 0;

  if (resto != parseInt(cpf.substring(9, 10), 10) ) 
    return false;

  soma = 0;
  for (let i = 1; i <= 10; i+=1) 
    soma += parseInt(cpf.substring(i-1, i), 10) * (12 - i);
  
  resto = (soma * 10) % 11;
  if ((resto == 10) || (resto == 11))
    resto = 0;

  if (resto != parseInt(cpf.substring(10, 11), 10) ) 
    return false;

  return true;
}

export const isCpfValid = (strCPF) => {
  const cpf = strCPF.replace(/[^0-9]/g, '');

  if ((cpf.length < 12) && !internalCpfValid(cpf)) {
    toast.warning('CPF inválido!');
    return false;
  }
  return true;
}

export const checkCPF = async (e, values, setValues) => {
  const cpf = e.target.value.replace(/[^0-9]/g, '');

  if (isCpfValid(cpf) && values?.client_Cpf) {
    setValues({ ...values, client_Cpf: cpf });
    const client = await getClientByCpf(cpf);
    if (client) {
      setValues({
        ...values,
        client_name: client.name,
        client_Cpf: client.cpf,
        client_birth_date: client.birth_date,
        client_CEP: client.address.cep,
        client_address_street: client.address.street,
        client_address_number: client.address.number,
        client_address_comp: client.address.comp,
        client_address_district: client.address.district,
        client_address_city: client.address.city,
        client_address_state: client.address.state,
        client_cellphone: client.cellphone,
        client_phone: client.phone,
        client_Email: client.mail,
        client_Rg: client.rg,
      });
    }
  }
};

export const checkProposal = async (context, values, setValues) => {
  if (values?.partner !== null && values?.proposal !== '') {
    try {
      let resp;
      if (context?.values?.leads.find((l) => l.proposal === values?.proposal)) {
        resp = context?.values?.leads.find((l) => l.proposal === values?.proposal);
      } else resp = await Proposal(values?.partner.code_wf, values?.proposal);
      const phone = `${resp.contato.find((r) => r.tipocodigo === 2)?.ddd}${
        resp.contato.find((r) => r.tipocodigo === 2)?.telefone
        }`.replace(/[^0-9]/g, '');
      const cellphone = `${resp.contato.find((r) => r.tipocodigo === 1)?.ddd}${
        resp.contato.find((r) => r.tipocodigo === 1)?.telefone
        }`.replace(/[^0-9]/g, '');
      const client = context?.values?.clients.find((c) => c.cpf === resp.cpf);
      const insurance = context?.values?.insurances.find((i) => i.name.includes(resp.tipoEstoque));
      if (resp) {
        const now = DateTime.now();
        const proposalDate = DateTime.fromISO(resp.dataProposta);
        // eslint-disable-next-line no-unused-vars
        const diffProposalNow = now.diff(proposalDate, ['hours']);
        setValues({
          ...values,
          client_name: client ? client.name : resp.clienteNome,
          client_Cpf: client ? client.cpf : resp.cpf,
          client_birth_date: client ? client.birth_date : resp.dataNascimento.split('T')[0],
          client_CEP: client ? client.address.cep : resp.cep,
          client_address_street: client ? client.address.street : resp.enderecoLogradouro,
          client_address_number: client ? client.address.number : resp.enderecoNumero.replace(/\s/g, ''),
          client_address_comp: client ? client.address.comp : resp.enderecoComplemento,
          client_address_district: client ? client.address.district : resp.bairro,
          client_address_city: client ? client.address.city : resp.cidade,
          client_address_state: client ? client.address.state : '',
          client_cellphone: client?.cellphone ? client.cellphone : cellphone,
          client_phone: client?.phone ? client?.phone : phone,
          client_Email: client ? client.mail : resp.email,
          proposal_date: resp.dataProposta,
          product: resp.veiculoDescricao,
          product_chassis: resp.chassi,
          product_license_plate: resp.placa,
          product_year: resp.ano,
          product_brand: resp.marca,
          insurance,
        });
      }
    } catch (err) {
      //
    }
  }
  return false;
};

export const checkCEP = async (values, setValues) => {
  if (values?.client_CEP != '') {
    try {
      const response = await CepVerify(values?.client_CEP?.replace(/[^\d]/g, ''));
      if (response) {
        setValues({
          ...values,
          client_address_state: response.uf,
          client_address_city: response.cidade,
          client_address_district: response.bairro,
          client_address_street: response.logradouro,
        });
      }
    }
    catch (error) {
      console.log('error:', error);
    }

  }
};
export const checkFinancialCEP = async (values, setValues) => {
  if (values?.fiancialCEP != '') {
    const response = await CepVerify(values?.client_CEP.replace(/[^\d]/g, ''));
    if (response) {
      setValues({
        ...values,
        financialUF: response.uf,
        financialCity: response.cidade,
        financialNeighborhood: response.bairro,
        financialAddress: response.logradouro,
      });
    }
  }
};

export const handleCall = async (values, setValues, setFinish, status) => {
  toast.info('Ligando!', { position: 'top-center' });
  if (values?.status?.id < 3) {
    setValues({
      ...values,
      status: status.find((s) => s.id == 3),
    });
    setFinish(true);
  }
};

export const verify = (values, setValues, car, permissions) => {
  let lostSale = values?.lost_sale;
  let productBrand = values?.product_brand;
  let productChassis = values?.product_chassis;
  let productLicensePlate = values?.product_license_plate;
  let productYear = values?.product_year;
  let backupCar = values?.backup_car;
  let pagueMenos = values?.pague_menos;
  let seguroConteudo = values?.seguro_conteudo;
  let competitorBroker = values?.competitor_broker;
  let Sold = values?.sold;


  if (!permissions?.can_finish) {
    backupCar = null;
    pagueMenos = null;
    seguroConteudo = null;
    Sold = null;
  }

  if (values?.sold?.id === 2) {
    lostSale = null;
  }
  if (values?.sold?.id === 1 && values?.lost_sale?.id !== 5) {
    competitorBroker = null;
  }

  if (car === false) {
    productBrand = '';
    productChassis = '';
    productLicensePlate = '';
    productYear = '';
  }

  setValues({
    ...values,
    lost_sale: lostSale,
    product_brand: productBrand,
    product_chassis: productChassis,
    product_license_plate: productLicensePlate,
    product_year: productYear,
    backup_car: backupCar,
    pague_menos: pagueMenos,
    seguro_conteudo: seguroConteudo,
    competitor_broker: competitorBroker,
    sold: Sold,
  });
};

export const getLeadPermissions = async (id) => {
  const response = await dataAll(`/leads-permissions/${id}`);
  return response;
};

export const sendFile = async (url, data, setValue, fileName, msg) => {
  try
  {
    const formData = new FormData();
    const keys = Object.keys(data);
    const values = Object.values(data);
    for (let i = 0; i < keys.length; i += 1) {
      formData.append(keys[i], values[i]);
    }

    const token = localStorage.getItem('token');
    await api.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${token}`,
        'File-Name': fileName
      }
    });

    if(msg)
      toast.success(msg);
    else
      toast.success('Arquivo enviado com sucesso');

    setValue(null);
    return true;
  }
  catch (error) {
    setValue(null);
    console.log(error);
    toast.error('Erro ao enviar arquivo');
    return error.response.data;
  }
}

export const getAge = (date) => {
  try {
    let birthDate = DateTime.fromISO(date);
    if(!birthDate.isValid)
    {
      birthDate = DateTime.fromFormat(date, 'dd/MM/yyyy');
    }

    const age = Interval.fromDateTimes(birthDate, DateTime.now());

    return age.length('years');
  }
  catch (error)
  {
    alert('Data inválida!');
    return 0;
  }
}

export const checkAge61 = (date, checkCondition) => {
  if (IsCorujaFlow() && checkCondition) {
    if (getAge(date) >= 61 ) {
      toast.warning('Idade superior a 61 anos!');
      return false;
    }
  }
  return true;
}

export const checkAge18 = (date, checkCondition) => {
  if (checkCondition) {
    if (getAge(date) < 18 ) {
      toast.warning('A Idade deve ser maior que 18 anos!');
      return false;
    }
  }
  return true;
}

export const handleSaveFlowService = (checkCondition, birthDate) => {
  const dateFromIso = DateTime.fromISO(birthDate); 
  if (dateFromIso.isValid)
  {
    if (dateFromIso > DateTime.now()) {
      toast.warning('Data de nascimento inválida!');
      return false;
    }
  }

  if (birthDate) {
    if (!checkAge18(birthDate, true))
      return false;
  }

  if (IsCorujaFlow()) {
    if (checkCondition && birthDate) {
      return checkAge61(birthDate, checkCondition);
    }
  }

  return true;
}

export const getNumParcelas = (termBegin, termFinal) => {
  try 
  {
    const interval = Interval.fromDateTimes(DateTime.fromFormat(termBegin, 'yyyy-MM-dd'), DateTime.fromFormat(termFinal, 'yyyy-MM-dd')); 

    return Math.trunc(interval.length('months')) -1;
  }
  catch
  {
    return 0;
  }
}

export const GetInstallmentCalc = (termBegin, currentDate) => {
  termBegin = new Date(termBegin);
  currentDate = new Date(currentDate);
  
  const yearDiff = (currentDate.getFullYear() - termBegin.getFullYear()) * 12;
  const mouthDiff = currentDate.getMonth() - termBegin.getMonth();
  
  return yearDiff + mouthDiff + 1;
}
