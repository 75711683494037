/* eslint-disable react/forbid-prop-types */
import { useState, useEffect, useRef } from 'react';
import P from 'prop-types';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import useSWR from 'swr';
import InputMask from 'react-input-mask';
import {
  IconButton,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
  Divider,
  Paper,
  FormGroup,
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import CloseIcon from '@material-ui/icons/Close';
import { useAuth } from '../../hooks/useAuth';
import { maskCpf, maskDateTime, maskCurrency, maskDate } from '../../utils/masks';
import api from '../../service/api';
import { fetcher } from '../../service/SWRFetcher';
import { Header, Container } from '../../styles/shared/styles';
import ModalHistoricoEndossos from '../ClientForm/modalHistoricoEndosso';
import ModalHistoricoFaturas from './invoiceHistoryModal';
import ConfirmModal from './confirmModal';
import { Loading } from '../Loading';
import UpdateCreditCard from './updateCreditCardModal';

export default function ModalAssinatura({
  open,
  setOpen,
  isPaymentPage,
  assinatura,
  loading,
  buscarAssinatura,
  setLoading,
}) {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenConfirmation = () => {
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleEstornar = () => {
    handleOpenConfirmation();
  };

  const [openAssinatura, setOpenConfirmAssinatura] = useState(false);

  const handleCancelar = () => {
    setOpenConfirmAssinatura(true);
  };

  const handleCloseConfirmAssinatura = () => {
    setOpenConfirmAssinatura(false);
  };

  const handleCancelarAssinatura = async () => {
    setLoading(true);
    try {
      const response = await api.delete(`/maistodos/assinaturarecorrente/${assinatura.id}`);

      if (response.status == 200 || response.status == 201) {
        toast.success(response.data.message);
        handleCloseConfirmAssinatura();
        await buscarAssinatura(assinatura.id);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);

      const msg = 'Não foi possível cancelar a Assinatura!';
      if (error.response && (error.response.status === 400 || error.response.status === 500)) {
        toast.error(`${msg} ${error.response.data.message}`);
      } else {
        toast.error(`${msg} ${error}`);
      }

      handleCloseConfirmAssinatura();
    }
  };

  const [openHistoricoFatura, setOpenHistoricoFatura] = useState(false);

  const handleOpenHistoricoFatura = () => {
    setOpenHistoricoFatura(!openHistoricoFatura);
  };

  const [openAtualizarCartao, setOpenAtualizarCartao] = useState(false);

  const handleCloseAtualizarCartao = () => {
    setOpenAtualizarCartao(false);
  };

  const handleOpenAtualizarCartao = () => {
    setOpenAtualizarCartao(true);
  };

  const traduzirStatus = (status) => {
    switch (status) {
      case 'paid':
        return 'Pago';
      case 'suspended':
        return 'Suspenso';
      case 'canceled':
        return 'Cancelado';
      case 'created':
        return 'Aguardando pagamento';
        case 'finished':
          return 'Finalizado';
      default:
        return status;
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth style={{ zIndex: 99 }}>
        <DialogTitle>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <h2>Detalhes da Assinatura</h2>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', verticalAlign: 'center' }}>
              <Loading />
            </div>
          ) : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>
                  <b>Lead:</b> {assinatura?.lead?.id}{' '}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>
                  <b>Identificador Mais Todos:</b> {assinatura.identificadorMaisTodos}{' '}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>
                  <b>Nome do Cliente:</b> {assinatura?.lead?.client?.name}{' '}
                </p>
                <h3>Status: {traduzirStatus(assinatura?.status)} </h3>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>
                  <b>Descrição:</b> {assinatura?.descricao}{' '}
                </p>
                <h4>Fim vigência: {maskDate(assinatura?.fimVigencia)} </h4>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>
                  <b>Taxa de adesão:</b> {maskCurrency(assinatura?.valorAdesao)}{' '}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <h2>Valor Assinatura: {maskCurrency(assinatura?.valorAssinatura)} </h2>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 10, paddingRight: 10 }}>
          <div>
            {!loading && !isPaymentPage && assinatura?.faturas.length > 0 && (
              <>
                <Button color="primary" onClick={handleOpenHistoricoFatura}>
                  Histórico Faturas
                </Button>
                <ModalHistoricoFaturas
                  open={openHistoricoFatura}
                  setOpen={setOpenHistoricoFatura}
                  title="Histórico Faturas"
                  faturas={assinatura.faturas}
                  assinatura={assinatura}
                />
              </>
            )}
            {!loading && assinatura?.status == 'paid' && (
              <>
                <Button color="primary" onClick={handleOpenAtualizarCartao}>
                  Atualizar Cartão
                </Button>
                <UpdateCreditCard
                  title="Atualizar Cartão de Crédito:"
                  open={openAtualizarCartao}
                  handleClose={handleCloseAtualizarCartao}
                  assinatura={assinatura}
                />
              </>
            )}
          </div>

          <div>
            {!loading && (assinatura?.status == 'paid' || assinatura?.status == 'suspended') && (
              <>
                <Button onClick={handleCancelar} color="secondary" medium>
                  Cancelar Assinatura
                </Button>
                <ConfirmModal
                  title="Confirmar cancelamento assinatura:"
                  description="Tem certeza que deseja cancelar a assinatura?"
                  open={openAssinatura}
                  handleConfirm={handleCancelarAssinatura}
                  handleCloseConfirmation={handleCloseConfirmAssinatura}
                />
              </>
            )}

            <Button onClick={handleClose} color="primary" medium>
              Fechar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ModalAssinatura.propTypes = {
  open: P.bool.isRequired,
  setOpen: P.func.isRequired,
  isPaymentPage: P.bool.isRequired,
  assinatura: P.object.isRequired,
  loading: P.bool.isRequired,
  buscarAssinatura: P.func.isRequired,
  setLoading: P.func.isRequired,
};
